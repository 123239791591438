
.stateSvg {
  width: 110%;
  height: 110%;
  animation: loading;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform: translate(-5%, 0);
}

@keyframes loading {
  0% { transform: translate(-5%,-95%); }
  50% { transform: translate(-5%, 95%); }
  100% { transform: translate(-5%, -95%); }
}
